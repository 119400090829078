import React from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Link from "./link.js";
import SocialMedia from "./socialmedia.js";
import Grid from "@material-ui/core/Grid";

export default (props) =>
{
    return (<footer className="clearfix" style={{
                backgroundImage:    "radial-gradient(circle at center center, #777777 30%, #000000 100%)",
                backgroundPosition: "0 0",
                backgroundRepeat:   "no-repeat",
                backgroundSize:     "auto 100%",
                backgroundColor:    "#2B2B2B",
                position:           "relative",
                color:      "#FFFFFF",
                textShadow: "2px 2px 2px rgba(0, 0, 0, 0.8)",
                textAlign:"center",
                marginTop:-170,
                paddingTop:105
            }}>
                <Container maxWidth="lg">
                    <Grid
                        container
                        direction="row"
                        justify="space-evenly"
                        alignItems="stretch"
                        spacing={3}
                    >
                        <Grid item xs={12}>
                            <Typography variant="h4" component="p" style={{
                            }}>How can we make your life easier?</Typography>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <img src="/logos/logo_456px.png" alt="Zum Productions" style={{
                                width:     150
                            }}/>
                            <Typography variant="h6" component="p" style={{
                            }}>Zum Productions</Typography>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <a target="_blank" href="https://zumproductions.atlassian.net/servicedesk/customer/portal/1" style={{
                                color:          "inherit",
                                textDecoration: "none"}}><Button color="primary" variant="contained">Contact us to get started</Button></a>
                        </Grid>
                        <Grid item md={4} sm={6} xs={12}>
                            <SocialMedia color="white"/>
                            <Typography variant="h6" component="p" style={{}}>Newsletter</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Link to={"/"+props.i18n+"/legal/terms"}>Terms of use</Link> | <Link to={"/"+props.i18n+"/legal/privacy"}>Privacy policy</Link>
                        </Grid>
                    </Grid>
                    <p style={{fontSize:70+"%",textShadow:"none"}}>&copy; 2000-2020 <a href="http://zumproductions.com" target="_blank" rel="noopener noreferrer" style={{color:"inherit",textDecoration:"none"}}>Zum Productions</a>. All rights reserved.</p>
                </Container>
            </footer>);
};
