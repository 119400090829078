import React from "react";
import { Helmet } from 'react-helmet';

import CssBaseline from '@material-ui/core/CssBaseline';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import theme from '../theme.js';
import Header from './header.js';
import Footer from './footer.js';

export default (props) =>
{
    const {children}       = props;
    
    return (<ThemeProvider theme={theme}>
                <Helmet>
                    <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no"/>
                    <meta name="apple-mobile-web-app-capable" content="no"/>
                    <meta name="Author" content="Zum Productions"/>
                    <meta name="Copyright" content="&copy; Zum Productions"/>
                    <meta name="description" content="Digital Agency & IT Services"/>
                    <meta name="keywords" content="websites, apps, hosting, marketing, e-learning, hardware"/>
                    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
                    <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
                </Helmet>
                
                <CssBaseline />
                <div>
                    <Header i18n={props.i18n} />
                    <div id="progressBar">
                        <span></span>
                    </div>
                    {children}
                    <Footer i18n={props.i18n} />
                </div>
            </ThemeProvider>);
};
