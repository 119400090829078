import React from 'react';
import Box from '@material-ui/core/Box';

export default (props) =>
{
    return <Box {...props} style={{
        margin:       "-60px 30px 120px",
        padding: 0,
        boxShadow:    "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
        borderRadius: 6,
        zIndex: 3,
        position: "relative",
        background: "#fff",
        textAlign:"center"
    }}/>;
};
