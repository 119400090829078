import React from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

export default (props) =>
{
    const styles =
    {
        color:"#fff",
        backgroundColor:"#000",
        borderRadius:50+"%",
        padding:7,
        width:30,
        height:30
    };
    switch(props.color)
    {
        case "black":
        break;
        case "white":
            styles.color = "#000";
            styles.backgroundColor = "#fff";
        break;
        default:
    }
    return (<>
        {false && <a href="#" target="_blank" style={{marginLeft: 3 + "px"}}>
            <FontAwesomeIcon icon={["fab", "linkedin-in"]} style={styles}/>
        </a>}
        {false && <a href="http://www.facebook.com/ZumProductions" target="_blank" style={{marginLeft: 3 + "px"}}>
            <FontAwesomeIcon icon={["fab", "facebook-f"]} style={styles}/>
        </a>}
        {false && <a href="#" target="_blank" style={{marginLeft: 3 + "px"}}>
            <FontAwesomeIcon icon={["fab", "instagram"]} style={styles}/>
        </a>}
        {false && <a href="http://twitter.com/zumproductions" target="_blank" style={{marginLeft: 3 + "px"}}>
            <FontAwesomeIcon icon={["fab", "twitter"]} style={styles}/>
        </a>}
    </>);
};
